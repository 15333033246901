import { request, METHOD } from "@/utils/request";
import { addurl } from "@/utils/util";
import { requestApiUrl } from "@/config/constConfig";

/**
 * 获取新闻列表页
 * @param page
 * @param pagesize
 * @param online  0-下架，1-上架
 * @param keyword
 * @param type p-获取部分字段
 */
export async function getNewsList(params) {
  return request(
    addurl("getList", requestApiUrl.requestNews) +
      "/" +
      params.page +
      "/" +
      params.pageSize,
    METHOD.GET,
    {
      query: params.keyword,
      online: 1,
      typeid: params.typeid,
      type: params.type||'',
    }
  );
}
/**
 * 获取详情
 * @param {*} doi 
 * @returns 
 */
export async function getInfo (doi) {
  return request(addurl('getInfo', requestApiUrl.requestNews) + '/' + doi, METHOD.GET, {
      type: 'p'
  })
}

/**
 * 获取根据当前新闻获取上下篇
 * @param {*} doi 
 * @returns 
 */
 export async function getPrevAndNextById (doi) {
  return request(addurl('getPrevAndNextById', requestApiUrl.requestApiNews) + '/' + doi, METHOD.GET)
}

/**
 * 获取广告
 * @param page
 * @param pagesize
 * @param type p-获取部分字段
 */
export async function getAdList(params) {
  return request(
    addurl("getList", requestApiUrl.requestAd) +
      "/" +
      params.page +
      "/" +
      params.size,
    METHOD.GET,
    {
      type: params.type,
    }
  );
}

